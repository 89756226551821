@import "styling/_variables.scss";

::-webkit-input-placeholder {
	text-align: center;
}

:-moz-placeholder {
	/* Firefox 18- */
	text-align: center;
}

::-moz-placeholder {
	/* Firefox 19+ */
	text-align: center;
}

:-ms-input-placeholder {
	text-align: center;
}

body {
	@include fontBase;
}

.App > .container {
	margin-bottom: 10rem;
	width: 100%;
	max-width: 1140px;
}

.App .card-header {
	background-color: $white;
}

.logoutPage,
.callbackPage,
.errorPage {
	max-width: 500px;
}

.logoutPage h2,
.callbackPage .alert,
.errorPage .alert {
	text-align: center;
}

.verification-container h4 {
	margin-top: 3rem;
	font-weight: bold;
}

.verification-container > .row {
	display: block;
	text-align: center;
}

.circle-border {
	border-radius: 50%;
	border: 1px #1c242f solid;
	width: 20px;
	height: 20px;
	display: inline-block;
	line-height: 15px;
	text-align: center;
	margin-left: 5px;
}

.verification-container h1 {
	color: $primary-bronze-1;
}

.contentBlock {
	padding-top: $general-padding-1;
	padding-bottom: 18px;
	.container {
		width: 92%;
	}
}

.contentBlock .card {
	margin: 30px 40px;
}

.contentBlock .mobileId #personalNumber,
.contentBlock .mobileId #mobileNumber {
	margin: 0 auto;
	min-width: 200px;
	text-align: center;
}

.contentBlock .mobileId .input-group {
	margin-bottom: 1rem;
}

.contentBlock .smartIdAdv .card {
	margin: 0;
}

.contentBlock .smartIdAdv .col-12 {
	.col-12 {
		padding: 0;
	}

	#nationalIdentityNumber {
		margin: 0 auto;
		min-width: 200px;
		text-align: center;
	}
}

.contentBlock .smartIdAdv .verification-container {
	padding: 0;
}

.contentBlock .smartIdAdv .card .verification-container h1 {
	font-size: 3rem !important;
}

.contentBlock .eParaksts .card {
	margin: 0;
}

.contentBlock .eParaksts .col-12 {
	.col-12 {
		padding: 0;
	}
}


/*
    Navigation
*/
#topnavbar.navbar-default {
	background-color: transparent;
	border-color: transparent;
	padding: 25px 0 25px;
}

#topnavbar.navbar-default .navbar-nav > li > a {
	color: $white;
}

#topnavbar .container {
	margin-bottom: 10rem;
}

#topnavbar > .container {
	width: 100% !important;
	max-width: 1140px;
	margin: 0 auto;
	padding: 0;
}

@media (max-width: 1200px) {
	#topnavbar > .container {
		padding: 0 15px;
	}
}

#topnavbar .navbar-right {
	padding: 0;
}

#topnavbar .logout {
	margin-left: 15px;
}

#topnavbar .logo {
	background: url(/img/logos/tfbank21-logo-white.svg);
	height: 45px;
	width: 195px;
	background-size: 195px;
	background-repeat: no-repeat;
}

.navbar-fixed-bottom {
	bottom: 0;
	margin-bottom: 0;
	border-width: 1px 0 0;
	right: 0;
	left: 0;
	z-index: 1030;
}

/*
 * The .authButtons for both signing and loggin in.
 */
.authButtons,
.authButtons {
	margin: 0 auto;
	padding: 0;
	width: 100%;
	display: flex;
	justify-content: center;
	max-width: 500px;
	background-color: $primary-warmgray-5;
	border-radius: inherit;
}

.authButtons .authButton {
	z-index: 2;
	background-color: $cream-rich;
	padding: 15px 10px;
}

.authButtons .authButton,
.authButtons .authButton:hover,
.authButtons .authButton:active,
.authButtons .authButton:focus,
.authButtons .authButton.btn-primary:not(:disabled):not(.disabled).active,
.authButtons .authButton.btn-primary:not(:disabled):not(.disabled):active {
	margin: 1px 0 0;
	filter: none;
	background-repeat: no-repeat;
	font-size: $font-size-small;
}

.authButtons .authButton:hover,
.authButtons .authButton:focus,
.authButtons .authButton:active,
.authButtons .authButton.chosen,
.authButtons .authButton.btn-primary:not(:disabled):not(.disabled).active,
.authButtons .authButton.btn-primary:not(:disabled):not(.disabled):active {
	z-index: 3;
	cursor: pointer;
	border-bottom: 0.3rem solid $primary-bronze-1;
}
.authButtons.row {
	display: flex;
	flex-direction: column;
}

.authButtons .authButton {
	width: 100%;
	background-size: 30px;
	padding: 15px 10px 13px 87px;
}

.authButtons .authButton:hover,
.authButtons .authButton:focus,
.authButtons .authButton:active,
.authButtons .authButton.chosen,
.authButtons .authButton.btn-primary:not(:disabled):not(.disabled).active,
.authButtons .authButton.btn-primary:not(:disabled):not(.disabled):active {
	background-color: $cream-rich;
}

.authButtons .buttonsBottomBorder {
	display: none;
}

/* Vendor specific login/signing buttons mobile*/
.authButton.EeIdCard {
	background-image: url("/img/mobile-id-logo.svg");
	background-position: 47px 8px;
}

.authButton.EeMobileId {
	background-image: url("/img/mobile-id-logo.svg");
	background-position: 47px 8px;
}

.authButton.EeSmartId {
	background-image: url("/img/smart-id-logo.svg");
	background-position: 49px 9px;
}

.authButton.PayseraId {
	background-image: url("/img/paysera2.svg");
	background-position: 49px 14px;
}

.authButton.eParaksts {
	background-image: url("/img/eParaksts-logo.png");
	background-position-y: 50% !important;
	background-size: 150px;
}

/* Desktop styling with custom breakpoint */
@media (min-width: 470px) {
	.authButtons .authButton {
		max-width: 470px;
		flex: auto;
		width: 10%;
		padding: 50px 0 13px 0;
		text-align: center;
		background-position: 50% 25%;		
	}

	.authButtons.row {
		flex-direction: row;
		background-color: $cream-rich;
	}
}

@media (max-width: 470px){
	.authButtons .authButton {
		min-height: 50px;
	}
	.authButton.eParaksts {
		background-position: 49px center;
		background-size: 100px;
	}	
}

/*
 * Navigation with white background.
 * And signing details
 */
.App.signing #topnavbar.navbar-default {
	background: $gradient-bronze;
	margin: 0 auto 4rem auto;
	padding: 0;
	height: 90px;
}

.App.signing #topnavbar > .container .navbar-header .navbar-brand {
	display: flex;
	margin: 0;
}

.App.signing #topnavbar > .container .navbar-right {
	margin: 0;
	padding: 2rem 0;
}

.App.signing .signingRoot .title {
	margin: 0 auto;
	width: 100%;
	padding: 0;
	border-bottom: 1px solid #d8d8d8;
}

.App.signing .signingRoot .title h2 {
	text-align: center;
	margin: 0 auto;
}

.App.signing .signingRoot .list-group {
	margin: 0 auto;
	width: 100%;
	max-width: 500px;
	background: $white;
	border-radius: 0.25rem;
}

.App.signing .signingRoot .list-group .list-group-item {
	border: 0;
}

.App.signing .signingRoot #signingRequestId {
	margin: 0 auto;
	min-width: 200px;
	max-width: 60%;
	text-align: center;
}

.App.signing .signDisclaimer {
	font-weight: bold;
}

.App.signing .signingCompleteBlock .backButton {
	margin: 10rem auto 5rem auto;
}

.App.signing span.orange {
	color: #b82126;
}

.App.signing #SignPage .signingBlock,
.App.signing #SignPage .signingCompleteBlock {
	margin: 0 auto;
	max-width: 500px;
}

.App.signing #SignPage .signingCompleteBlock h2 {
	text-align: center;
}

.App.signing #SignPage {
	.card {
		background-color: $primary-warmgray-2;
		.card-header {
			padding: 0;
			border-bottom: 0;
			background-color: $primary-warmgray-2;
		}
	}
}

.App.signing #SignPage #mobileNumber {
	margin: 0 auto;
	min-width: 200px;
	max-width: 60%;
	text-align: center;
}

.App.signing .PayseraSign .row .col .row .col-sm-4 img {
	width: 100%;
}

@media (max-width: 576px) {
	.App.signing .PayseraSign .row .col .row .col-sm-4 img {
		max-width: 140px;
	}
}

@media (max-width: 768px) {
	.App.signing #topnavbar > .container .navbar-header .navbar-brand {
		padding: 1.5rem 0;
	}

	.App.signing #topnavbar > .container .navbar-right > div:not(.logout) {
		position: relative;
		top: 1px;
	}

	#topnavbar .logo.signrelated {
		background-size: 130px;
	}

	#topnavbar .logo {
		background-size: 130px;
	}

	.App.signing #topnavbar > .container .logout {
		margin: 0;
	}
}

/*
 * Login page
 */
div.loginHeadline {
	margin: 2rem auto;
	color: $white;
	text-align: center;
	max-width: 500px;
}

@media (max-width: 320px) {
	div.loginHeadline {
		margin: 0 auto;
	}
}

@media (max-width: 768px) {
	div.loginHeadline {
		margin: 0 auto 1rem auto;
	}
}

@media (max-width: 470px) {
	#LoginPage {
		padding: 0;
	}
}

div.loginBody {
	background-color: $primary-warmgray-2;
	max-width: 500px;
	border-radius: $border-radius-base;

	padding: 1.5rem 0 0 0;
	@media (min-width: 470px) {
		//padding-top: 1.5rem;
		padding: 0;
		.PayseraSign .row {
			.col .row .col-sm-4 img {
				width: 100%;
			}

			h3 {
				padding: 0 2rem;
			}
		}
	}
}

div.cancelLogin {
	margin-top: 3rem;
}

/*
 * Logout page
 */
.App .logoutPage.container {
	max-width: 500px;
}

.App .logoutPage.container .list-group-item {
	padding: 0;
}

/*
    PDF related
*/
#divNoSigningComponent {
	display: none;
}

.divPdfControls {
	margin: 0 auto 1.2rem auto;
	text-align: center;
	display: flex;
	flex-direction: row;
	justify-content: center;
}

.divPdfControls > * {
	flex: 0 1 auto;
	width: 40px;
	height: 40px;
	margin: 0.3rem;
}

.divPdfControls > div {
	text-align: center;
	font-size: 2rem;
	line-height: 1.9;
	width: 60px;
}

.divPdfControls button {
	background-color: $primary-warmgray-9;
	border: 1px solid #d8d8d8;
	border: 0;
	border-radius: 0.3rem;
	color: $white;
}

.divPdfControls .glyphicon {
	top: 2px;
	left: 1px;
	line-height: 1.2;
	font-size: 2.5rem;
}

.divPdfControls .glyphicon-chevron-down {
	top: 4px;
}

#divOrigDoc {
	padding-top: 2rem;
}

#divSignedDoc {
	padding-top: 2rem;
	padding-bottom: 2rem;
	display: none;
}

#divReturnLink {
	padding-bottom: 4rem;
}

#canvasPdf {
	border: 1px solid #d8d8d8;
	margin-bottom: 0.5rem;
	background: $white;
	max-width: 100%;
}

#canvas-container {
	overflow: auto;
	border: 1px solid $primary-warmgray-9;
	max-height: 1300px;
}

@media only screen and (max-width: 900px) {
	#canvasPdf {
		min-width: initial;
	}
}

/*
    Misc
*/
.sb {
	border: 1px solid green;
}

.stateSigned {
	display: none;
}

.stateServiceError {
	display: none;
}

.width-150 {
	width: 150px;
}

.text-white {
	color: white;
}

.alert.show {
	width: 100%;
	max-width: 500px;
}

.alert.show .close {
	font-size: 2.4rem;
}

/*
    Language
*/
#topnavbar .language-selector img {
	max-height: 30px;
	border-radius: 1.5rem;
	padding-right: 0.3rem;
}

#topnavbar .language-button button {
	background: none;
	padding: 0 1rem;
	border-color: transparent;
	border: 0;
	border-radius: 0.25rem !important;
}

#topnavbar .language-button button::after {
	color: $white;
}

.App.signing #topnavbar .language-button button::after,
#topnavbar .language-button.show button::after,
#topnavbar .language-button:hover button::after {
	color: $black;
}

#topnavbar .language-button button:focus,
#topnavbar .language-button button:active,
#topnavbar .language-button button:hover {
	background: rgba(246, 245, 238, 0.7);
	border: 0 !important;
	box-shadow: none !important;
}

#topnavbar .language-button > button[aria-expanded="true"] .btn-outline-light {
	color: initial;
}

#topnavbar .language-button .dropdown-menu {
	border-radius: 0.25rem;
	opacity: 0.9;
	background: rgba(246, 245, 238, 0.75);
	border: 0;
	padding: 0;
	margin-top: 1px;
}

@media (max-width: 768px) {
	#topnavbar .language-button .dropdown-menu {
		background: $white;
	}
}

#topnavbar .language-button .dropdown-menu {
	border: 1px solid #f6f5ee;
}

#topnavbar .language-button .dropdown-menu a.dropdown-item {
	display: flex;
	justify-content: space-between;
	border-radius: 0.25rem;
	padding: 0 22px;
	margin: 0.3rem 0;
}

#topnavbar .language-button .dropdown-menu a.dropdown-item:hover {
	background-color: rgba(133, 127, 123, 0.5);
}

#topnavbar .language-button .dropdown-menu a.dropdown-item > div:nth-child(1) {
	align-self: center;
}

#topnavbar .language-button .dropdown-menu a.dropdown-item > div:nth-child(2) {
	margin-left: 0.5rem;
}

#topnavbar
	.language-button
	.dropdown-menu
	a.dropdown-item
	> div:nth-child(2)
	img {
	border-radius: 1.5rem;
	max-height: 30px;
}

/*
    Spinners
    Modals
    Notifications
*/
.spinner-border {
	width: 6rem;
	height: 6rem;
	margin: 3rem 0;
	animation-duration: 2s;
	color: $primary-bronze-1;
}

.modal-body .btn {
	width: 100%;
	text-align: justify;
}

.modal-body .dropdown-toggle::after {
	position: absolute;
	top: 18px;
	right: 15px;
}

.modal-dialog img {
	max-width: 100%;
}

.notification.row {
	padding: 2rem;
}

.notification.row > div {
	margin: 0;
	padding: 0;
}

.notification.row > div:not(.glyphicon) {
	padding-left: 1.3rem;
}

.notification.row > div.glyphicon {
	color: $primary-warmgray-11;
}

.notification.row > div.glyphicon::before {
	position: relative;
	top: 0.5rem;
	left: 0.5rem;
}

@media (max-width: 576px) {
	.notification.row > div.glyphicon::before {
		top: -1rem;
		left: 0;
		display: flex;
		justify-content: center;
	}
}

/*
    Footer
*/
#footerContent {
	position: fixed;

	@media (max-width: 470px) {
		position: relative;
	}
}

#footerContent p {
	color: $white;
	padding-top: 1rem;
	width: 100%;
}

@media (max-width: 320px) {
	#footerContent p {
		font-size: 1rem;
	}
}

/*
    Some buttons
*/
#btnSignMobile {
	margin-bottom: 20px;
}

/*
    Bootstrap overriders
*/
.alert {
	margin: 2rem auto 2rem auto;
}

.btn {
	@include btnBase;
	display: block;
}

.btn-primary {
	@include btnPrimaryBronze;
}

.btn-outline-secondary {
	@include btnOutlineDarkGray;
}

.btn-logout-light {
	@include btnOutlineLightGray;
}

.btn-light {
	@include btnOutlineLightGray;
}

.btn-warning {
	color: $white;
	background-color: $brown;
	border-color: $brown;
	&:not(:disabled):not(.disabled) {
		&.active,
		&:active,
		&:focus,
		&:hover {
			background-color: #b87c28;
			border-color: #b87c28;
		}
	}
}

.btn-danger {
	color: $white;
	background-color: $danger;
	border-color: $danger;
	&:not(:disabled):not(.disabled) {
		&.active,
		&:active,
		&:focus,
		&:hover {
			background-color: #b82126;
			border-color: #b82126;
		}
	}
}

/*
.btn.dropdown-toggle {
    border-radius: 0.25rem;
    padding: 0;
    background-color: $white;
    color: #000000;
    border: 1px solid #d8d8d8;
}

.dropdown > .btn.dropdown-toggle:hover,
.dropdown > .btn.dropdown-toggle:active,
.dropdown > .btn.dropdown-toggle:focus {
    background-color: #5B7F95;
    color: $white;
    border-color: #5B7F95;
    filter: unset;
}
*/

.dropdown-toggle::after {
	vertical-align: 0.155rem;
}

.form-control,
input[type="text"],
.input-group-text {
	font-size: 1.6rem;
	border: 0;
	border-bottom: 1px solid #d8d8d8;
	padding: 0.6rem;
}

input[type="checkbox"].form-control {
	font-size: 0.8rem;
	border: 1px solid #d8d8d8;
}
