:root,
html {
    font-size: 62.5%;
}

body {
    min-width: 320px;
    margin: 0;
    font-family: 'Barlow', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    font-size: 1rem;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

h1 {
    font-size: 3.5rem;
    margin: 0;
}

h2 {
    font-size: 2.5rem;
    padding: 2rem;
    text-align: left;
    font-weight: bold;
    margin: 0;
}

@media (max-width: 576px) {
    h1 {
        font-size: 2.8rem;
        padding: 1rem 0;
    }
}

@media (max-width: 320px) {

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        text-align: center;
    }

    h1 {
        font-size: 2rem;
    }

    h2 {
        font-size: 1.8rem;
    }

    #footer {
        padding: 0;
        margin-top: 3rem;
    }

}


p,
div,
a {
    font-size: 1.6rem;
}

.mw200 {
    max-width: 200px;
}

.mw300 {
    max-width: 300px;
}
