@import "styling/_variables.scss";

html body {
  background-image: url(/img/woman_hanging_outside_car_window.png);
  background-position: top center;
  background-attachment: fixed;
  background-size: cover;
}

.defaultSigningBlock p.disabled {
  text-align: center;
  color: $primary-bronze-1;
  margin-top: 10px;
}

.defaultSigningBlock .btn-primary:disabled {
  background-color: $primary-warmgray-2;
  color: $primary-warmgray-11;
  border-color: $primary-warmgray-2;
}

.defaultSigningBlock .btn-primary:hover:disabled,
.defaultSigningBlock .btn-primary:focus:disabled {
  box-shadow: none;
  filter: none;
  box-shadow: #857f7b;
}
